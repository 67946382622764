import React, { useContext } from 'react';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-feather';

import StrapiLink from '../StrapiLink/StrapiLink';
import { PageContext } from '../PageContext';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type CrumbType = {
  crumbLabel: string;
  pathname: string;
};

type BreadCrumbType = {
  lastCrumb?: string;
  pageContext: any;
  hasRightToLeft?: boolean;
};

const Breadcrumb = ({ pageContext, lastCrumb, hasRightToLeft = false, locale }: BreadCrumbType) => {
  let crumbs: any = pageContext.breadcrumb?.crumbs;
  const { root } = pageContext.pathPrefixes;
  if (root !== '/') {
    crumbs = crumbs.filter((el: {pathname: string; crumbLabel: string }) => `${el.pathname}/` !== root);
  }
  const elements = crumbs.map((item: CrumbType, i: number, arr: CrumbType[]) => {
    // remove home crumb
    if (i === 0) return false;
    // add page title as custom last crumb if it exists
    if (locale && item.crumbLabel === locale.toLowerCase()) return false;
    if (i === arr.length - 1 && lastCrumb?.length) {
      return { pathname: item.pathname, crumbLabel: lastCrumb };
    }
    const newlast = item.crumbLabel.replace(/-/g, ' ');
    return { pathname: item.pathname, crumbLabel: newlast };
  });
  const { websiteLocale } = useContext(PageContext);
  const { hasRightToLeftLanguage } = v4tov3base({...websiteLocale.attributes});
  return (
    <div className={classnames(hasRightToLeft ? styles.breadcrumbsRTL : styles.breadcrumbs)}>
      {elements.filter(Boolean).map((value: CrumbType, index: number, arr: CrumbType[]) => {
        return (
          <React.Fragment key={`${value.crumbLabel}-${index}`}>
            {
              lastCrumb === value.crumbLabel
                ? <span className={classnames(styles.link, styles.greyLink)}>{value.crumbLabel}</span>
                : <StrapiLink
                  url={pageContext.pathPrefixes.root !== '/' ? value.pathname.replace(pageContext.pathPrefixes.root, '') : value.pathname}
                  title={value.crumbLabel}
                  className={classnames(styles.link)}
                  hideIcon={true}
                />
            }
            {arr.length - 1 !== index && (hasRightToLeftLanguage ? <ChevronLeft size={16} className={styles.chevron} /> : <ChevronRight size={16} className={styles.chevron} />)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
